// Messages sent from core app (parent) to SPF (child)
export const ADDRESS_SUBMITTED = 'ADDRESS_SUBMITTED';
export const ADDRESS_TOKEN_RECEIVED = 'ADDRESS_TOKEN_RECEIVED';
export const DEVICE_FINGERPRINT_RECEIVED = 'DEVICE_FINGERPRINT_RECEIVED';

// Messages sent from SPF (child) to core app (parent)
export const IFRAME_HEIGHT_CHANGED = 'IFRAME_HEIGHT_CHANGED';
export const PAYMENT_CANCELED = 'PAYMENT_CANCELED';
export const PAYMENT_FAILED = 'PAYMENT_FAILED';
export const PAYMENT_SAVED = 'PAYMENT_SAVED';
export const PAYMENT_VALIDATION_FAILED = 'PAYMENT_VALIDATION_FAILED';
export const GUEST_PAYMENT_PLACE_ORDER_CLICK =
  'GUEST_PAYMENT_PLACE_ORDER_CLICK';
